.tag {
    position: absolute;
    width: 64px;
    height: 24px;
    text-align: center;
    color: #fff;
    z-index: 1;
}
.tag p {
    margin-bottom: 0px;
    padding: 4px;
    border: 0px;
    border-radius: 6px 0 20px 0;
    /* border-top-left-radius: 6px;
    border-bottom-right-radius: 6px; */
}
