.accordion-button:not(.collapsed) {
    color: var(--black);
    background-color: #f5f5f5;
}
.accordion-button:focus {
    border-color: unset !important;
    box-shadow: none;
}
.side__menu-item {
    text-decoration: none;
    list-style: none;
}
.side__menu-item > li > a {
    text-decoration: none;
    list-style: none;
    color: var(--black);
    /* padding: 10px; */
    font-size: 14px;
}
.side__menu-heading {
    color: var(--black);
    font-size: 18px;
}
