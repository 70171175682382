.searchTerm {
    width: 25%;
    border-right: none;
    padding: 20px;
    height: 16px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: var(--theme-color);
    /* box-shadow: 0 2px 15px rgb(2 59 109 / 12%); */
    transition: 0.3s;
    margin-left: -33px;
}

.searchTerm:focus {
    color: var(--theme-color);
}

.search__bar_button {
    width: 110px;
    height: 42px;
    border: 1px solid var(--theme-color);
    background: var(--theme-color);
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 18px;
    text-align: left;
    padding: 0;
}
