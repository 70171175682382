.more__products-heading h2{
    font-weight: 700;
    font-size: 16px;
}
.more__products-one-image img{
    height: 79px;
    width: 85px;
}
.more__products-one-content h2{
    font-weight: 400;
    font-size: 18px;
}
.more__products-one-content p{
    font-weight: 400;
    font-size: 20px;
}
.more__products-one-content-review p{
    font-size: 13px;
}
.more__products-one-content span{
    text-decoration: line-through;
    font-size: 14px;
}
.more__products-one-content p{
    font-size: 18px;
    color: black;
}