.Register__heading p {
  font-weight: 400;
  font-size: 16px;
}
.Register__heading a{
    color: var(--theme-color);
}
.Register__heading a:hover{
    color: #000;
}
.btn-Register{
    width: 100%;
    background-color: var(--theme-color);
    color: var(--bg-color);
    height: auto;
    padding: 10px;
    border: none;
    border-radius: 5px;
}
.form-control:focus {
    color: #212529 !important;
    background-color: #fff !important;
    border-color: var(--theme-color) !important;
    outline: 0;
    /* box-shadow: none !important; */
    box-shadow: none!important;
}
.register__footer a{
    color: var(--theme-color);
    padding-left: 10px;
}
.register__footer a:hover{
    color: #000;
}
.register__divider{
    position: relative;
}
.register__divider-inner{
    position: absolute;
    left: 165px;
    /* right: 7px; */
    background-color: #fff;     
    padding: 0px 5px 0px 8px;
    bottom: 6px;
}
.register-hide-btn{
    position: absolute;
    background-color: transparent;
    border: none;
    right: 38px;
    top: 376px;
}
@media (min-width: 300px) and (max-width: 400px){
    .register__divider-inner{
        position: absolute;
        left: 170px;
    }
}
@media (min-width: 410px) and (max-width: 512px){
    .register__divider-inner{
        position: absolute;
        left: 188px;
    }
}
@media (min-width: 514px) and (max-width: 820px){
    .register__divider-inner{
        position: absolute;
        left: 339px;
    }
}
@media (min-width: 820px) and (max-width: 912px){
    .register__divider-inner{
        position: absolute;
        left: 346px;
    }
}
@media (min-width: 912px) and (max-width: 1000px){
    .register__divider-inner{
        position: absolute;
        left: 462px;
    }
}
@media (min-width: 1024px) and (max-width: 1280px){
    .register__divider-inner{
        position: absolute;
        left: 467px;
    }
}