.account__setting-card{
    border: 1px solid #777777;
    border-radius: 5px;
    padding: 10px;
}
.account__setting-card i{
    font-size: 20px;
    color: #777777;
}
.account__setting-card p{
    font-size: 18px;
    color: #777777;
}
.ccount__setting-card .active{
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--bg-color);
}
.account__setting-card:hover{
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--bg-color);
    cursor: pointer;
}
.account__setting-card:hover p{
    color: var(--bg-color);
}
.account__setting-card:hover svg path{
    fill: #fff;
    stroke: #fff;
}