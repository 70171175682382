.vendor__info-profile img {
  width: 100px;
  height: 100px;
}
.vendor__info-profile-content h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  /* cursor: pointer; */
}
.vendor__info-profile-content p {
    font-size: 16px;
    /* line-height: 26px; */
  }
  .vendor__info h2{
    font-weight: 700;
    font-size: 16px;
    /* line-height: 26px; */
  }
  .vendor__info-profile-name p{
      font-size: 14px;
  }
  .vendor__info-profile-address p{
    font-size: 14px;
  }