.order__summary {
  background-color: var(--bg-color-3);
  height: auto;
  width: auto;
  border: 1px solid #b7b7b7;
}
.order__summary-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 8px;
  gap: 95px;
}
.order__summary-header h2 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
}
.order__summary-header button{
    background-color: var(--bg-color-3);
    font-weight: 700;
    border: none;
}
.order__summary-inner-sec-image img {
  width: 82px;
  height: 82px;
}
hr{
    color: #322f2f;
    background-color: #726d6d;
}
.order__summary-inner-sec-content p{
  font-size: 14px;
}
.order__summary-inner-sec-content h4{
    font-size: 18px;
  }
  .order__summary-inner-sec-content h5{
    font-size: 16px;
    color: var(--theme-color);
  }
  .coupon__code-input{
      width: 250px;
  }
  .coupon__code-sec button{
    width: 110px;
    height: auto;
    padding: 7px;
    border: 1px solid var(--theme-color);
    background: var(--theme-color);
    text-align: center;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
  }
  .order__summary-inner-sec-amount h2{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  .order__summary-inner-sec-amount-total h2{
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
  }
  .order__summary-inner-sec-amount h3{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color:var(--theme-color)
  }
  .order__summary-inner-sec-amount p{
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
.order__summary-inner-sec-amount-btn button{
    width: 180px;
    height: auto;
    padding: 7px;
    border: 1px solid var(--theme-color);
    background: var(--theme-color);
    text-align: center;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
}
