.add-field {
  background-color: var(--bg-color);
  border: none;
  cursor: pointer;
}
.main-heading h2 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
.radio-btn .form-check-input{
  width: 22px !important;
  height: 21px !important;
  padding: 5px;
}
.radio-btn label.form-check-label{
  padding-left: 10px;
}