.user__profile{
    border: 1px solid #B7B7B7;
    padding: 0 60px;
    border-radius: 5px;
    margin: 10px;
}
.user__profile-heading h2{
    font-size: 20px;
    font-weight: 700;
}
.user__profile-personal-info h2{
    font-size: 20px;
    font-weight: 700;
}
.user__profile-personal-info label{
    color: #777777;
    font-size: 14px;
}
.user__profile-personal-info .divider{
    width: 97%;
    margin: 0 auto;
}
.user__profile-personal-info-footer-content h3{
    font-size: 19px;
    font-style: normal;
}
.user__profile-personal-info-footer-content p{
    font-size: 14px;
    color: #777777;
}
.form-check-input:checked{
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
}
.form-check-input{
    height: 1.5rem !important;
    width: 3rem !important;
}
.user__profile-personal-info .btn-save{
    background-color: var(--theme-color);
    border: none;
    color: var(--bg-color);
    padding: 8px 16px;
    border-radius: 5px;
}
.show-hide-btn{
    position: relative;
    left: 0;
    right: 0;
    padding-left: -437px;
    margin-left: -35px;
    border: none;
    align-items: center;
    background-color: #0000;
}