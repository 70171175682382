.visit__store-heading h2{
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}
.visit__store-detail-shipping-content h2{
    font-size: 16px;
    font-weight: 600;
}
.visit__store-detail-shipping-content p{
    font-size: 14px;
}
.visit__store-detail {
    border: 1px solid #B7B7B7;
    border-radius: 7px;
    width: 100%;
    padding: 16px;
}
.visit__store-heading img{
    width: 17px;
}