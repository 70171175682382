.view__cart-header {
    background-color: var(--theme-color);
    height: 45px;
    border-radius: 5px;
}
.view__cart_left {
    background-color: #b7b7b7;
    height: 300px;
    width: auto;
    border: 1px solid #b7b7b7;
}
.view__cart-header-content {
    color: var(--bg-color);
    font-size: 18px;
    margin-bottom: 0;
}
.view__cart-header-space {
    background-color: #21a58b;
    height: 43px;
    width: 39px;
    border-radius: 5px 0px 0px 5px;
}
.view__cart_right-main-heading {
    border: 1px solid #e9ecef;
    border-bottom: 10px solid #fff;
}
.view__cart_right-main-heading th {
    background-color: #ececec;
    padding: 18px 0;
    font-family: "Quicksand", sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #253d4e;
}
.view__cart_right-main-body h6 {
    font-size: 20px;
    line-height: 89px;
    font-weight: 500;
}
.view__cart_right-main-body h4 {
    font-size: 18px;
    line-height: 89px;
    font-weight: 500;
}
.view__cart_right-main-body .detail-extralink .detail-qty {
    /* margin: 0 6px 15px 0; */
    background: #fff;
    border: 1px solid #e5e7eb !important;
    font-size: 16px;
    font-weight: 700;
    /* color: #3BB77E; */
    /* border-radius: 5px; */
    /* padding: 11px 20px 11px 30px; */
    max-width: 100px;
    height: 45px;
}
.view__cart_right-main-body .detail-extralink .detail-qty a {
    padding: 10px;
}
.view__cart_right-main-body .del-icon {
    padding-top: 40px;
}
.view__cart_right-main-body-product-thumbnail {
    max-width: 87px;
    width: 80px;
    padding-right: 30px;
}
.view__cart_right-main-body-product-thumbnail img {
    width: 100%;
}
.view__cart_left {
    background-color: #f5f5f5;
    width: auto;
    height: 100%;
}
.view__cart_left h2 {
    font-size: 25px;
    font-weight: 700;
}
.view__cart_left p {
    font-size: 19px;
    font-weight: 500;
}
.view__cart_left-border-bottom {
    border-bottom: 1px solid #b7b7b7;
}
.view__cart_left-btn button {
    background-color: var(--theme-color);
    height: 34px;
    min-width: 292px;
    border: none;
    border-radius: 4px;
    color: var(--bg-color);
}
.coupon_code {
    border: 1px solid #b7b7b7;
    width: 290px;
}
.coupon_code p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
.update-cart-btn button {
    background-color: var(--theme-color);
    height: 40px;
    min-width: 180px;
    border: none;
    border-radius: 4px;
    color: var(--bg-color);
}
.arrow-icon {
    background-color: var(--bg-color);
    border: 1px solid #ced4da;
    border-left: none;
}
.arrow-icon:hover {
    background-color: var(--bg-color);
    border-color: #ced4da;
}
.arrow-icon:focus {
    border-color: #b7b7b7 !important;
    background-color: var(--bg-color);
}
.view__cart_left-customer-amount label.form-label {
    font-size: 14px;
}
.qty-btn {
    flex-basis: 40%;
    background: transparent;
    border: 0;
}
.qty-val {
    flex-basis: 20%;
    text-align: center;
}
.qty-down {
    text-align: left;
    border: 0;
    background: transparent;
}
.qty-up {
    text-align: right;
}
.view__cart-heading {
    padding-left: 18px !important;
}
