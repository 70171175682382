
.footer-container {
    background-color: var(--theme-color);
}

.footer-text{
    font-size: 0.7rem;
    color: var(--bg-color);
}
.footer-text-2{
    font-size: 1rem;
}
.address-box{
    border: 1px solid lightgrey;
    padding: 5px;

}
hr{
    color: lightgrey;
    background-color: lightgrey;
}
.footer-heading{
    font-size: 1.0625rem;
    font-weight: bold;
    color: var(--bg-color);
}
.footer-text-3{
    font-size: 0.7rem;
}
.border-right{
    border-right: 1px solid lightgrey;
    height: 176px;
}
.footer-copyright{
    color: #808080;
    font-size: 15px;
}
.image-icon{
    width: 61px;
}
.image-icon-2{
    width: 196px;
}
@media (min-width: 375px) and (max-width: 746px){
    .border-right {
        border: none;
    }
    .footer-text-3{
        text-align: center;
    }
    .footer-text-2{
        text-align: center;
    }
    .footer-copyright{
        text-align: center;
    }
}

