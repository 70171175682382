
.otp__send-input-btn button{
    display: inline;
    background-color: var(--theme-color);
    border: none;
    color: var(--bg-color);
    width: 100px;
    height: auto;
    padding: 7px;
    border-radius: 0 5px 5px 0;
    margin-top: 30px;
}
.otp__send-input .phone-input{
    border-radius: 5px 0 0 5px;
    width: 243px;
}
.back-to-login span{
    color: var(--theme-color);
    cursor: pointer;
    text-decoration: underline;
}