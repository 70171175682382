.vendor__profile{
    box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
    background-color: #fff;
    height: 85vh;
    padding: 25px;
}
.vendor__profile .vendor__profile-image img{
    width: 100px;
    height: 100px;
}
.vendor__profile .vendor__profile-info h2{
    font-size: 18px;
    font-weight: 700;
}
.vendor__profile .vendor__profile-info p{
    font-size: 14px;
    color: #6B7280;
}
.vendor__profile .vendor__profile-info button{
    background-color: #fff;
    color: var(--theme-color);
    border: none;
    font-size: 16px;
}
.vendor__profile .vendor__profile-phone h2{
    font-size: 16px;
    color: #1F2937;
}
.vendor__profile .vendor__profile-phone p{
    font-size: 15px;
    color: #6B7280;
}
.vendor__profile .vendor__profile-rating h3{
    font-size: 17px;
    font-weight: bold;
}
.vendor__profile .vendor__profile-rating p{
    font-size: 40px;
    font-weight: bold;
}