.help__center{
    border: 1px solid #B7B7B7;
    padding: 0 60px;
    border-radius: 5px;
    margin: 10px;
}
.help__center-heading h2{
    font-size: 20px;
    font-weight: 700;
}
.help__center-content .accordion-button:not(.collapsed){
    color: #000; 
    background-color: #fff;
    box-shadow: none; 
}
.help__center-content .accordion-body{
    font-size: 14px;
    color: #777777;
}