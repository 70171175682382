:root {
    --bg-1: #FFEED6;
    --bg-2:#D9ECD2;
    --bg-3:#EFD8D4;
}
/* .categories__home_card{
    height: 110px;
} */
.categories__home_card_content h2{
    font-family: 'Manrope';
    font-style: normal;
    font-size: 18px;
}
.categories__home_card_content p{
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    color: #595959;
}