.cart__banner{
    background-image: url(/src/assets/images/cart-banner.jpg);
    /* background-color: #E0ECE2; */
    height: 45vh;
    background-size: cover;
    background-repeat: no-repeat;
}
.cart__banner h2{
    font-weight: 900;
    font-size: 48px;
}
.cart__banner img{
    height: 300px;
    width: 300px;
}