.user__wishlist {
  border: 1px solid #b7b7b7;
  padding: 0 60px;
  border-radius: 5px;
  margin: 10px;
}
.user__wishlist-heading h2 {
  font-size: 20px;
  font-weight: 700;
}
.user__wishlist-content h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.user__wishlist-content h3 {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #B7B7B7;
}
.user__wishlist-content p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.user__wishlist-content h4 {
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color);
}
