.user__notification {
  border: 1px solid #b7b7b7;
  padding: 0 60px;
  border-radius: 5px;
  margin: 10px;
}
.user__notification-heading h2 {
  font-size: 20px;
  font-weight: 700;
}
.user__notification-content-personal-info h2 {
  font-size: 18px;
  font-style: normal;
}
.user__notification-content-personal-info p {
  font-size: 14px;
  font-style: normal;
  color: #777777;
}
.user__notification-content-personal-info h3 {
  font-size: 20px;
  font-weight: 700;
}
.user__notification-content-personal-info .btn-save {
  background-color: var(--theme-color);
  border: none;
  color: var(--bg-color);
  padding: 8px 16px;
  border-radius: 5px;
}
