.add_to_cart__products-content h2 {
  font-size: 17px;
  font-weight: 600;
}
.add_to_cart__products-content span {
  color: var(--theme-color);
  font-size: 14px;
}
.add_to_cart__products-content p {
  color: #777777;
  font-size: 14px;
}
.add_to_cart__products-image img {
  width: 43px;
  border: 1px solid #eaeaea;
  padding: 6px;
}
.cart__delBtn {
  background: transparent;
  border: 0;
  width: 32px;
}
.add_to_cart__checkout {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width:90%;
    background-color: #fff;
  }
  .add_to_cart__checkout-btn button {
    font-family: "Red Hat Display";
    font-style: normal;
    background-color: var(--theme-color);
    border: none;
    border-radius: 5px;
    padding: 7px;
    color: white;
    width: 100%;
    font-size: 18px;
  }
  .add_to_cart__view-cart-btn button {
    font-family: "Red Hat Display";
    font-style: normal;
    background-color: #fff;
    border: 1px solid #02b290;
    border-radius: 5px;
    padding: 7px;
    color: #02b290;
    font-size: 18px;
    width: 100%;
  }
  .add_to_cart__total-price h2 {
    font-size: 24px;
    font-weight: 700;
  }
  .add_to_cart__total-price p {
    font-size: 24px;
    font-weight: 700;
    color: var(--theme-color);
  }
