.catergories__thumb-image img {
  width: 84px;
  height: 90px;
}
.catergories__main-image img {
  height: 474px;
}
.catergories__content-size-box {
  height: 30px;
  width: 40px;
  background-color: #e5e7eb;
  border-radius: 4px;
}
.catergories__add-to-cart-btn {
  width: 129px;
  height: auto;
  border: 2px solid var(--theme-color);
  background-color: var(--bg-color);
  border-radius: 5px;
}
.catergories__add-to-cart-btn-sub a {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: var(--theme-color);
  color: var(--bg-color);
  border-radius: 20px;
  margin: 0 auto;
}
.catergories__add-to-cart-btn-add a {
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-color);
  color: var(--bg-color);
  border-radius: 20px;
}
.catergories__add-to-cart-btn p {
  color: var(--theme-color);
  text-align: center;
  margin: 0 auto;
}
.catergories__add-to-cart button {
  background-color: var(--theme-color);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  width: 155px;
  color: var(--bg-color);
}
.catergories__content-heading h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}
.catergories__content-heading p {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}
.catergories__content-heading span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-left: 10px;
}
.catergories__content-heading-two h1 {
  font-weight: 700;
  font-size: 32px;
}
.size-box ul li {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  margin-right: 10px;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  background-color: #eff2f7;
  border: 1px solid #eff2f7;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.size-box ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  color: #212529;
  font-weight: 400;
}
.size-box ul li.active {
  border: 1px solid var(--theme-color);
}
.catergories__add-to-cart-btn .input-number {
  border-radius: 0;
  margin-left: -1px;
  border: none;
  color: var(--theme-color);
  width: 57px;
}
.catergories__content .buy__btn {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
  display: inline-block;
  font-size: 15px;
  line-height: 10px;
  padding: 18px 18px 17px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  border-radius: 9px;
  width: 88%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.catergories__content .buy__btn:hover {
  background-color: var(--theme-color);
  color: #fff;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
}
.bread__crumb {
  font-size: 14px;
}
.bread__crumb i {
  font-size: 10px;
}
.catergories__add-to-cart .product__addToCart-button button {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border: 0px;
  height: 2.25rem;
  background-color: var(--theme-color) !important;
  cursor: pointer;
}

@media  (min-width: 300px) and (max-width: 1400px) {
  .catergories__add-to-cart{
    display: flex;
    flex-direction: column;
  }
}
