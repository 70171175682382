.cart__btn {
    background-color: var(--theme-color) !important;
    color: var(--bg-color) !important;
    display: inline-grid !important;
}
.wrapper {
    position: fixed;
    top: 271px;
    bottom: 0;
    /* left: 0px; */
    right: 0px;
    z-index: 9;
    height: 98px;
}
.cart__btn .btn-primary:focus {
    box-shadow: none !important;
    border-color: var(--bg-color) !important;
}
.btn:focus {
    box-shadow: none !important;
    border-color: var(--bg-color) !important;
}
.btn-primary {
    border-color: var(--bg-color) !important;
}
.btn-close {
    background-color: var(--theme-color) !important;
    border-radius: 20px !important;
    color: var(--bg-color) !important;
    opacity: 1 !important;
}
.cart-heading {
    font-size: 25px;
    font-weight: 700;
}
