
.home__page_card {
background-size: cover;
background-repeat: no-repeat;
height: 300px;
/* width: 510px; */
}

.home__page_card h2 {
    color: #fff;
}

.home__page_card p {
    color: #fff;
    font-size: 1rem;
}

.home__page_card_btn {
color:var(--light);
background-color: var(--theme-color);
border-color: var(--theme-color);
}

.home__page_card_btn:hover{
    background-color: var(--theme-color);
    border-color: #fff;
}
.home__page_card_btn .btn-primary:active{
    background-color: var(--theme-color) !important;
}