.filter__header {
    background-color: #f5f5f5;
    padding: 14px 0px;
    border-radius: 5px;
    width: auto;
    position: relative;
}
.filter__header-dropdown {
    background-color: var(--bg-color);
    border-radius: 30px;
    width: 257px;
}
.filter__header-content {
    position: absolute;
    right: 0;
}
@media (min-width: 300px) and (max-width: 500px) {
    .filter__header-dropdown{
        width: 104px;
        margin-left: -7px !IMPORTANT;
    }
}