.header__wrapper {
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.logo {
    cursor: pointer;
}
.nav_bg {
    background-color: var(--bg-color);
    box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
}
.Navbar {
    padding: 15px;
}
.custom_link a {
    color: var(--black);
}
.custom_link a:hover {
    color: var(--theme-color);
}
.dropdown_header {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    color: var(--theme-color);
}
.dropdown_header a {
    color: var(--theme-color) !important;
    padding: 9px 9px;
    border-bottom: 1px solid #e6e6e6;
}
.dropdown_header a:hover {
    color: var(--theme-color);
}
.header-search {
    flex-basis: 44%;
}

.header-search-animated {
    animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(40px);
	}
	100% {
		transform: translateY(4px);
	}
}
.nav__transition {
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.header-search .searchTerm {
    width: 57%;
    transition-timing-function: ease-in;
    transition: all 0.5s;
    margin-left: 11px;
}
.header-search .searchTerm:focus {
    color: var(--theme-color) !important;
}
.dropdown {
    width: 155px;
}
.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    background-color: var(--theme-color) !important;
    color: var(--bg-color) !important;
}
.dropdown-item a:hover {
    color: var(--theme-color) !important;
}
.dropdown-menu.show {
    font-size: 12px;
    width: 37px;
}
.dropdown-menu.show {
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    border: 1px solid #e6e6e6;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
}
.btn-nav-header {
    background-color: var(--theme-color);
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 9px;
}
@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}
@media (min-width: 300px) and (max-width: 600px) {
    .header-search {
        display: none !important;
    }
    .logo{
        margin-left: 45px;
        margin-bottom: 10px;
    }
}
@media (min-width: 700px) and (max-width: 900px) {
    .header-search {
        display: none !important;
    }
}
