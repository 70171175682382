.product__heading {
    text-align: center;
    margin-bottom: 38px;
    margin-top: 58px;
}
.product__heading h2 {
    font-family: var(--product-font-family);
    font-weight: var(--bold);
}
.product__heading p {
    font-family: var(--product-font-family);
    font-size: var(--font-size-regular);
    color: #595959;
}

.product__addToCart-button button {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: 0px;
    height: 2.25rem;
    background-color: #f3f4f6 !important;
    cursor: pointer;
}
.flex-1 {
    flex: 1 1 0%;
}
.product__cartIcon {
    background-color: #e5e7eb;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: 0.1s ease-in;
}
.product__whatsapp-icon {
    background-color: #e5e7eb;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: 0.1s ease-in;
}
.icon-minus {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.product__image {
    width: 100%;
    height: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e7eb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    position: relative;
}
.product__info {
    text-align: center;
    padding-top: 12px;
}
.product__info img {
    margin-bottom: 8px;
}
p.product__name {
    margin-bottom: 5px;
    color: var(--product-title-color);
    font-size: var(--product-font-size);
    font-family: var(--product-font-family);
}
p.product__price {
    font-size: var(--product-font-size);
    font-family: var(--product-font-family);
    color: var(--product-price-color);
    font-weight: var(--bold);
}
.product__wrapper {
    margin-bottom: 46px;
    border: 1px solid #e5e7eb;
    padding-bottom: 24px;
    border-radius: 7px;
}
.product__wrapper:hover {
    webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
    box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
    border: 1px solid #bce3c9;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
}
.addText {
    color: #4b5563;
    font-family: var(--product-font-family);
}
.product__addToCart-button button {
    border-radius: 5px;
    transition: 0.1s ease-in;
}
.product__addToCart-button button:hover {
    background-color: #02b290 !important;
    transition: 0.1s ease-in;
}
.product__addToCart-button button:hover .addText {
    color: #fff;
}
.product__addToCart-button button:hover .product__cartIcon {
    background-color: #06d6ae;
    transition: 0.1s ease-in;
}
.product__addToCart-button button:hover .product__whatsapp-icon {
    background-color: #06d6ae;
    transition: 0.1s ease-in;
}
.product__addToCart-button button:hover path {
    stroke: #fff;
    transition: 0.1s ease-in;
}
.product__addToCart-button button:hover .product__whatsapp-icon path {
    /* stroke: #fff; */
    fill: #fff;
    transition: 0.1s ease-in;
}
.product__addToCart-button button path {
    transition: 0.1s ease-in;
}
.product__image {
    cursor: pointer;
}
.animation-img {
    width: 40%;
    position: absolute;
}
.shake {
    animation: shrink 0.8s ease-in-out both 0.5s;
    z-index: 999999;
}

@keyframes shrink {
    0% {
        opacity: 0.8;
        right: 7vw;
        transform: scale(0.8);
    }

    100% {
        transform: scale(0);
        opacity: 0;
        right: -73vw;
        width: 0;
        height: 0;
        padding: 0;
        /* left: -273vw; */
        display: none !important;
    }
}
