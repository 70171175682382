.mobile__app {

    background-color: var(--bg-color-2);
}
.mobile__app_heading {

    font-size: 2.625rem;
    color: var(--text-color);
}
.mobile__app_para {

    font-size: 1.25rem;
    color: var(--text-color);
}