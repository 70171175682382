.details__customer-content-image img{
    max-width: 200px;
    height: 210px;

}
.details__customer-content h2{
    font-size: 16px;
    font-weight: 700;
}
.details__customer-content p{
    font-size: 14px;
}
ul.mb-3.nav.nav-tabs {
    border-bottom: 1px solid black;
}
.nav-tabs .nav-link.active{
    background-color: #0000;
    color: #000;
    border-color: #0000;
    border-bottom: 3px solid var(--theme-color);
    /* text-decoration: underline; */
}
.nav-link{
    color: #777777;
}
.nav-link:hover{
    color: #000;
}
.nav-tabs .nav-link:hover{
    border-color: #0000;
}