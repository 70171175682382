.order__list {
  border: 1px solid #b7b7b7;
  padding: 0 60px;
  border-radius: 5px;
  margin: 10px;
}
.order__list-header img {
  position: absolute;
  right: 17px;
  top: 9px;
}
.order__list-header h2 {
  font-size: 20px;
  font-weight: 700;
}
.order__list-header .form-control {
  width: 250px;
}
.order__list-table .table-border-bottom {
  border-bottom: 1px solid #b7b7b7;
}
.order__list-table-heading {
  background-color: #f6f6f6;
}
.order__list-table-heading th {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: 500;
}
.order__list-table-detail td {
  padding: 19px 12px;
  color: #000;
  font-size: 14px;
}
.order__list-table-detail .bullet {
  height: 11px;
  width: 10px;
  display: inline-block;
  margin-right: 6px;
}
.order__list-table .page-link {
  border: none;
  color: #000;
}
.order__list-table .page-link:focus {
  box-shadow: none;
  background-color: #fff;
}
.order__list-table .page-item.active .page-link {
  z-index: 3;
  color: var(--theme-color);
  background-color: #fff;
  border-color: #fff;
}
.search-bar{
  position: relative;
}
@media (min-width: 300px) and (max-width: 600px) {
  .order__list-header .form-control {
    width: 156px;
    display: block;
    font-size: 14px;
  }
  .order__list-header img {
    position: absolute;
    right: 12px;
    top: 10px;
    height: 17px;
  }
}
