.legal__notice{
    border: 1px solid #B7B7B7;
    padding: 0 60px;
    border-radius: 5px;
    margin: 10px;
}
.legal__notice-heading h2{
    font-size: 20px;
    font-weight: 700;
}
.legal__notice-content h2{
    font-size: 18px;
    font-style: normal;
}
.legal__notice-content p{
    font-size: 15px;
    font-style: normal;
    color: #777777;
}