.change__password{
    border: 1px solid #B7B7B7;
    padding: 0 60px;
    border-radius: 5px;
    margin: 10px;
}
.change__password-heading h2{
    font-size: 20px;
    font-weight: 700;
}
.change__password-input h2{
    font-size: 20px;
    font-weight: 700;
}
.change__password-input label{
    color: #777777;
    font-size: 14px;
}
.change__password-input .divider{
    width: 97%;
    margin: 0 auto;
}
.change__password-input .btn-save{
    background-color: var(--theme-color);
    border: none;
    color: var(--bg-color);
    padding: 8px 16px;
    border-radius: 5px;
}