@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css");

* {
  box-sizing: border-box;
}

html {
  font-family: "Red Hat Display", sans-serif;
}

body {
  margin: 0;
}
:root {
  --light: #f3f3f5;
  --bg-color-2: #f2f2f2;
  --bg-color-3: #f5f5f5;
  --bg-color: #fff;
  --black: #000;
  --theme-color: #02b290;
  --bs-white-rgb: 255, 255, 255;
  --product-title-color: #777777;
  --product-font-size: 1.125rem;
  --product-font-family: "Red Hat Display", sans-serif;
  --product-price-color: #02b290;
  --bold: 700;
  --font-size-regular: 1rem;
}
body {
  overflow-x: hidden;
}

/* Home Page CSS START */

.home__cat {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
}
.home__cat img {
  width: 100%;
}
.grid-col-span-2 {
  grid-column: span 2;
}
.grid-col-line-3 {
  grid-column-start: 1;
}
.child1 {
  grid-row-start: 1;
  grid-row-end: 3;
}
.home__cat .btn {
  padding: 0.5rem 2rem;
  border: 0px;
}
.cat__child {
  position: relative;
}
.catBtn-wrap {
  position: absolute;
  bottom: 49px;
  left: 50%;
  transform: translateX(-50%);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--theme-color) !important;
}
.user-setting .nav-item {
  border: 1px solid #777777;
  border-radius: 5px;
}
.user-setting .nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover svg path {
  fill: #fff;
  stroke: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover i {
  fill: #fff;
  stroke: #fff;
}
/* Home Page CSS END */
