.otp__code{
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    width: auto;
    height: auto;
    padding: 20px;
    margin: 0 auto;
}
.otp__code-input-field{
    width: 42px !important;
    margin: 12px;
    height: 60px;
    border: 1px solid var(--theme-color) !important;
}
.otp__send-input-btn-1 button{
    background-color: var(--bg-color);
    border: 1px solid grey;
    color: var(--bg-color);
    width: 120px;
    color:black ;
    height: auto;
    padding: 7px;
    border-radius: 5px;
    }
.otp__send-input-btn-2 button{
background-color: var(--theme-color);
border: none;
color: var(--bg-color);
width: 120px;
height: auto;
padding: 9px;
border-radius: 5px;
}